var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "product column items-center" },
        [
          _vm.widgetShowText && (!_vm.services || !_vm.services.length)
            ? _c(
                "q-card",
                {
                  staticClass:
                    "limit-width-420 overflow-hidden q-card-grouped q-card-flat on-top-default",
                  attrs: { inline: "" }
                },
                [
                  _c("q-card-title", { staticClass: "no-padding" }, [
                    _c(
                      "div",
                      { staticClass: "row justify-start items-center" },
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              mode: "out-in",
                              appear: "",
                              "enter-active-class":
                                "animated slideInUp animated-d400",
                              "leave-active-class":
                                "animated slideOutDown animated-d200"
                            }
                          },
                          [
                            _vm.channel &&
                            _vm.channel.orders !== 0 &&
                            (_vm.product.data.business.hoo[_vm.today_day]
                              .is24 ||
                              (_vm.today_time_elapsed >= 0 &&
                                _vm.today_time_elapsed <= 100) ||
                              (_vm.today_time_elapsed < 0 &&
                                _vm.today_time_elapsed > -10))
                              ? _c(
                                  "div",
                                  {
                                    key: "brb",
                                    staticClass:
                                      "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Change in Operations\n          "
                                    )
                                  ]
                                )
                              : _vm.channel &&
                                !_vm.product.data.business.hoo[_vm.today_day]
                                  .isClosed &&
                                (_vm.product.data.business.hoo[_vm.today_day]
                                  .is24 ||
                                  (_vm.today_time_elapsed >= 0 &&
                                    _vm.today_time_elapsed <= 100))
                              ? _c(
                                  "div",
                                  {
                                    key: "good",
                                    staticClass:
                                      "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-educate"
                                  },
                                  [_vm._v("\n            Ready\n          ")]
                                )
                              : _vm.today_time_elapsed < 0 &&
                                _vm.today_time_elapsed > -10
                              ? _c(
                                  "div",
                                  {
                                    key: "prepping",
                                    staticClass:
                                      "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-attention"
                                  },
                                  [
                                    _vm._v(
                                      "\n            Getting Ready\n          "
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    key: "others",
                                    staticClass:
                                      "text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-shallow"
                                  },
                                  [_vm._v("\n            Zzz\n          ")]
                                )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "transition",
            {
              attrs: {
                appear: "",
                "enter-active-class": "animated fadeInUp animated-d800",
                "leave-active-class": "animated fadeOutUp animated-d800"
              }
            },
            [
              !_vm.services || !_vm.services.length
                ? _c(
                    "q-card",
                    {
                      key: "guardian",
                      ref: "product-card-guardian",
                      staticClass:
                        "no-border limit-width-420 overflow-hidden q-card-grouped q-card-widget",
                      class: {
                        "q-card-widget-guard":
                          !_vm.channel ||
                          (_vm.channel &&
                            !_vm.channel.online &&
                            _vm.channel.orders === 0) ||
                          (_vm.today_time_elapsed < 0 ||
                            _vm.today_time_elapsed > 100) ||
                          _vm.product.data.business.hoo[_vm.today_day].isClosed,
                        "q-card-widget-guard-online":
                          _vm.channel &&
                          _vm.channel.online &&
                          _vm.channel.orders === 0 &&
                          !_vm.product.data.business.hoo[_vm.today_day]
                            .isClosed &&
                          (_vm.product.data.business.hoo[_vm.today_day].is24 ||
                            (_vm.today_time_elapsed >= 0 &&
                              _vm.today_time_elapsed < 90)),
                        "q-card-widget-guard-warning":
                          (_vm.channel &&
                            (_vm.channel.orders !== 0 &&
                              !(
                                _vm.product.data.business.hoo[_vm.today_day]
                                  .isClosed ||
                                (_vm.today_time_elapsed < 0 ||
                                  _vm.today_time_elapsed > 100)
                              ))) ||
                          (_vm.channel &&
                            _vm.channel.online &&
                            !_vm.product.data.business.hoo[_vm.today_day]
                              .is24 &&
                            !_vm.product.data.business.hoo[_vm.today_day]
                              .isClosed &&
                            ((_vm.today_time_elapsed >= 90 &&
                              _vm.today_time_elapsed <= 100) ||
                              (_vm.today_time_elapsed < 0 &&
                                _vm.today_time_elapsed > -10)))
                      },
                      attrs: { inline: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.openURL(_vm.productShortURI)
                        }
                      }
                    },
                    [
                      _vm.today_day
                        ? [
                            _vm.channel === null
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    indeterminate: "",
                                    color: "hint",
                                    height: "8px"
                                  }
                                })
                              : _vm.channel === false
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    percentage: 100,
                                    color: "hint",
                                    height: "8px"
                                  }
                                })
                              : _vm.channel.online === 0 ||
                                _vm.channel.orders !== 0
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    percentage: 100,
                                    stripe: "",
                                    color: "protect",
                                    height: "8px"
                                  }
                                })
                              : _vm.product.data.business.hoo[_vm.today_day]
                                  .is24
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    indeterminate: "",
                                    color: "educate",
                                    height: "8px"
                                  }
                                })
                              : _vm.product.data.business.hoo[_vm.today_day]
                                  .isClosed
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    percentage: 100,
                                    stripe: "",
                                    color: "protect",
                                    height: "8px"
                                  }
                                })
                              : _vm.today_time_elapsed >= 0 &&
                                _vm.today_time_elapsed < 100
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    indeterminate: "",
                                    color:
                                      _vm.today_time_elapsed >= 90
                                        ? "attention"
                                        : "green-d",
                                    height: "8px"
                                  }
                                })
                              : _vm.today_time_elapsed < 0 &&
                                _vm.today_time_elapsed > -10
                              ? _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    stripe: "",
                                    indeterminate: "",
                                    color: "attention",
                                    height: "8px"
                                  }
                                })
                              : _c("q-progress", {
                                  staticStyle: { "margin-bottom": "-8px" },
                                  attrs: {
                                    percentage: 100,
                                    stripe: "",
                                    color: "protect",
                                    height: "8px"
                                  }
                                })
                          ]
                        : _vm._e(),
                      _c(
                        "q-card-title",
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                mode: "out-in",
                                appear: "",
                                "enter-active-class":
                                  "animated fadeIn animated-d400",
                                "leave-active-class":
                                  "animated fadeOut animated-d200"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  key: _vm.channel
                                    ? "guardian-info-img-" +
                                      _vm.channel.online +
                                      "-" +
                                      _vm.channel.orders
                                    : "guardian-info-img-null",
                                  staticClass: "row justify-start items-center"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "left on-left-lg items-center row text-center justify-center",
                                      staticStyle: {
                                        width: "55px",
                                        height: "70px"
                                      }
                                    },
                                    [
                                      _vm.channel === false
                                        ? _c("img", {
                                            staticClass:
                                              "brighten-50 dark-brighten-100",
                                            attrs: {
                                              src:
                                                "/statics/_demo/circle.b3line_white.svg",
                                              width: "48"
                                            }
                                          })
                                        : !_vm.channel
                                        ? _c("img", {
                                            staticClass:
                                              "brighten-50 dark-brighten-100",
                                            attrs: {
                                              src:
                                                "/statics/_demo/circles4_white.svg",
                                              width: "48"
                                            }
                                          })
                                        : _vm.channel.orders !== 0 ||
                                          (_vm.channel &&
                                            _vm.channel.online &&
                                            !_vm.product.data.business.hoo[
                                              _vm.today_day
                                            ].isClosed &&
                                            (_vm.today_time_elapsed >= 90 &&
                                              _vm.today_time_elapsed <= 100))
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/exclamationmark_white.svg",
                                              width: "48"
                                            }
                                          })
                                        : _vm.channel &&
                                          _vm.channel.online &&
                                          !_vm.product.data.business.hoo[
                                            _vm.today_day
                                          ].isClosed &&
                                          (_vm.product.data.business.hoo[
                                            _vm.today_day
                                          ].is24 ||
                                            (_vm.today_time_elapsed >= 0 &&
                                              _vm.today_time_elapsed < 90))
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/heart_white.svg",
                                              width: "48"
                                            }
                                          })
                                        : _vm.channel &&
                                          _vm.channel.online &&
                                          (_vm.today_time_elapsed < 0 &&
                                            _vm.today_time_elapsed > -10)
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                "/statics/_demo/heart_white.svg",
                                              width: "48"
                                            }
                                          })
                                        : _vm.channel.online === 0 &&
                                          (_vm.product.data.business.hoo[
                                            _vm.today_day
                                          ].is24 ||
                                            (_vm.today_time_elapsed >= 0 &&
                                              _vm.today_time_elapsed <= 100))
                                        ? _c("img", {
                                            staticClass:
                                              "brighten-50 dark-img-brighten-50 dark-img-invert-100",
                                            attrs: {
                                              src:
                                                "/statics/_demo/exclamationmark_white.svg",
                                              width: "48"
                                            }
                                          })
                                        : _c("img", {
                                            staticClass:
                                              "brighten-50 dark-img-brighten-50 dark-img-invert-100",
                                            attrs: {
                                              src:
                                                "/statics/_demo/xmark_white.svg",
                                              height: "48"
                                            }
                                          })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      key: _vm.channel
                                        ? "guardian-info-text-" +
                                          _vm.channel.online +
                                          "-" +
                                          _vm.channel.orders
                                        : "guardian-info-text-null",
                                      staticClass: "float-left line-height-sm"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-size-140p capitalize"
                                        },
                                        [
                                          _vm.channel === null
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("CONNECTING"))
                                                )
                                              ])
                                            : _vm.channel === false
                                            ? _c("span", [
                                                _vm._v(_vm._s(_vm.$t("NEW")))
                                              ])
                                            : (_vm.channel.orders !== 0 &&
                                                (_vm.product.data.business.hoo[
                                                  _vm.today_day
                                                ].is24 ||
                                                  (_vm.today_time_elapsed >=
                                                    0 &&
                                                    _vm.today_time_elapsed <=
                                                      100))) ||
                                              (_vm.channel.online === 0 &&
                                                (_vm.product.data.business.hoo[
                                                  _vm.today_day
                                                ].is24 ||
                                                  (_vm.today_time_elapsed >=
                                                    0 &&
                                                    _vm.today_time_elapsed <=
                                                      100)))
                                            ? _c("span", [
                                                _vm.channel.orders === 0
                                                  ? _c("span", [
                                                      _vm._v("Notice")
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getStatusChangeData(
                                                            _vm.channel.orders
                                                          ).label
                                                        )
                                                      )
                                                    ])
                                              ])
                                            : _vm.product.data.business.hoo[
                                                _vm.today_day
                                              ].is24
                                            ? _c("span", [_vm._v("Open")])
                                            : _vm.product.data.business.hoo[
                                                _vm.today_day
                                              ].isClosed
                                            ? _c("span", [_vm._v("Closed")])
                                            : _vm.today_time_elapsed < 0 &&
                                              _vm.today_time_elapsed > -10
                                            ? _c("span", [
                                                _vm._v("Opening Soon")
                                              ])
                                            : _vm.today_time_elapsed < 0 ||
                                              _vm.today_time_elapsed > 100
                                            ? _c("span", [_vm._v("Closed")])
                                            : _vm.today_time_elapsed >= 90 &&
                                              _vm.today_time_elapsed <= 100
                                            ? _c("span", [
                                                _vm._v("Closing Soon")
                                              ])
                                            : _c("span", [_vm._v("Open")])
                                        ]
                                      ),
                                      _vm.channel === false
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-weight-regular font-size-100p block on-top-sm",
                                              attrs: { slot: "subtitle" },
                                              slot: "subtitle"
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "EMPTY_COMMUNICATION"
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.channel
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-weight-regular font-size-100p block on-top-sm",
                                              attrs: { slot: "subtitle" },
                                              slot: "subtitle"
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _vm.today_day
                                                    ? [
                                                        _vm.product.data
                                                          .business.hoo[
                                                          _vm.today_day
                                                        ].is24
                                                          ? [_vm._v("24 Hours")]
                                                          : _vm.product.data
                                                              .business.hoo[
                                                              _vm.today_day
                                                            ].isClosed
                                                          ? [
                                                              _vm._v(
                                                                "Not open today."
                                                              )
                                                            ]
                                                          : [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "t12format"
                                                                    )(
                                                                      _vm
                                                                        .product
                                                                        .data
                                                                        .business
                                                                        .hoo[
                                                                        _vm
                                                                          .today_day
                                                                      ].open
                                                                    )
                                                                  ) +
                                                                  "-" +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "t12format"
                                                                    )(
                                                                      _vm
                                                                        .product
                                                                        .data
                                                                        .business
                                                                        .hoo[
                                                                        _vm
                                                                          .today_day
                                                                      ].close
                                                                    )
                                                                  ) +
                                                                  "\n                  "
                                                              )
                                                            ]
                                                      ]
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "absolute text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lg",
                              attrs: { slot: "right" },
                              slot: "right"
                            },
                            [
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    mode: "out-in",
                                    appear: "",
                                    "enter-active-class":
                                      "animated400 bounceIn",
                                    "leave-active-class":
                                      "animated400 bounceOut"
                                  }
                                },
                                [
                                  /*!isConciergeOnline &&*/ !_vm.clients
                                    .length ||
                                  _vm.channelFetchActiveClientsRequestsCount ||
                                  _vm.channelFetchActiveClientsRequestActive
                                    ? _c(
                                        "div",
                                        {
                                          key: "chip-work",
                                          staticClass:
                                            "chip-live chip-live-blue"
                                        },
                                        [
                                          _c("q-icon", {
                                            attrs: {
                                              color: "white",
                                              name: "ion-code-working"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm.isConciergeOnline
                                    ? _c(
                                        "div",
                                        {
                                          key: "chip-live",
                                          staticClass: "chip-live"
                                        },
                                        [_vm._v("LIVE")]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "transition",
            {
              attrs: {
                appear: "",
                "enter-active-class": "animated fadeInUp animated-d800",
                "leave-active-class": "animated fadeOutUp animated-d800"
              }
            },
            [
              _vm.services && _vm.services.length
                ? _c(
                    "q-list",
                    {
                      staticClass:
                        "text-system-brand text-weight-medium q-list-depth",
                      staticStyle: {
                        "max-width": "320px !important",
                        margin: "10px auto !important"
                      },
                      attrs: { striped: "", "gradient-bottom": "" }
                    },
                    _vm._l(_vm.services, function(service, serviceIndex) {
                      return _c(
                        "q-item",
                        {
                          key: "service-" + serviceIndex,
                          attrs: {
                            item: "",
                            separator: _vm.widgetShowSeparator
                          }
                        },
                        [
                          _c("q-item-side", { staticClass: "text-center" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  "/statics/services/" +
                                  service.iconDetails.categoryId +
                                  "." +
                                  service.iconDetails.componentId +
                                  ".svg",
                                width: "33"
                              }
                            }),
                            _vm.serviceStatus(service.uuid) >= 2
                              ? _c("img", {
                                  staticClass: "absolute",
                                  staticStyle: {
                                    top: "7px",
                                    left: "17px",
                                    height: "36px"
                                  },
                                  attrs: {
                                    src: "statics/_demo/cross-overlay.svg"
                                  }
                                })
                              : _vm._e()
                          ]),
                          _c(
                            "q-item-main",
                            [
                              _c(
                                "q-item-tile",
                                {
                                  staticClass:
                                    "capitalize text-weight-semibold",
                                  attrs: { label: "" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        service.label
                                          ? service.label
                                          : _vm.Wings.services.list[
                                              service.iconDetails.categoryId
                                            ][service.iconDetails.componentId]
                                              .name
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              service.label && _vm.widgetShowType
                                ? _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.Wings.services.list[
                                              service.iconDetails.categoryId
                                            ][service.iconDetails.componentId]
                                              .name
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              service.components.length === 1 &&
                              _vm.isCustomDescriptor(
                                _vm.product.data.business.components[
                                  service.components[0]
                                ]
                              )
                                ? _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          class: _vm.serviceStatusClass(
                                            _vm.serviceStatus(service.uuid)
                                          )
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getComponentDescriptors(
                                                _vm.product.data.business
                                                  .components[
                                                  service.components[0]
                                                ]
                                              ).availability[
                                                _vm.serviceStatus(service.uuid)
                                              ].label
                                            )
                                          )
                                        ]
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(
                                          _vm.getComponentDescriptors(
                                            _vm.product.data.business
                                              .components[service.components[0]]
                                          ).availability[
                                            _vm.serviceStatus(service.uuid)
                                          ].sublabel
                                        ) + "\n          "
                                      )
                                    ]
                                  )
                                : _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _c(
                                        "b",
                                        {
                                          class: _vm.serviceStatusClass(
                                            _vm.serviceStatus(service.uuid)
                                          )
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.Wings.services.defaults
                                                .descriptors.availability[
                                                _vm.serviceStatus(service.uuid)
                                              ].label
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                            ],
                            1
                          ),
                          _c(
                            "q-item-side",
                            { attrs: { right: "" } },
                            [
                              _c("q-icon", {
                                attrs: {
                                  name: _vm.serviceStatusIcon(
                                    _vm.serviceStatus(service.uuid)
                                  ),
                                  color: _vm
                                    .serviceStatusClass(
                                      _vm.serviceStatus(service.uuid)
                                    )
                                    .split("text-")[1],
                                  size: "32px"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }