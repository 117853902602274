<template>
  <div v-if="ready" class="product column items-center">
    <!-- primary: status-as-text -->
    <q-card v-if="widgetShowText && (!services || !services.length)" inline class="limit-width-420 overflow-hidden q-card-grouped q-card-flat on-top-default">
      <q-card-title class="no-padding">
        <div class="row justify-start items-center">
          <transition mode="out-in" appear enter-active-class="animated slideInUp animated-d400" leave-active-class="animated slideOutDown animated-d200">
            <div key="brb" v-if="channel && channel.orders !== 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100) || (today_time_elapsed < 0 && today_time_elapsed > -10))" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-red">
              Change in Operations
            </div>
            <div key="good" v-else-if="channel && !product.data.business.hoo[today_day].isClosed && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100))" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-educate">
              Ready
            </div>
            <div key="prepping" v-else-if="(today_time_elapsed < 0 && today_time_elapsed > -10)" class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-attention">
              Getting Ready
            </div>
            <div key="others" v-else class="text-family-brand block margin-auto-lr font-size-120p text-weight-bolder text-shallow">
              Zzz
            </div>
          </transition>
        </div>
      </q-card-title>
    </q-card>
    <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutUp animated-d800">
      <q-card v-if="!services || !services.length" @click.native="openURL(productShortURI)" key="guardian" inline class="no-border limit-width-420 overflow-hidden q-card-grouped q-card-widget"
        :class="{
          'q-card-widget-guard': !channel || (channel && !channel.online && channel.orders === 0) || (today_time_elapsed < 0 || today_time_elapsed > 100) || product.data.business.hoo[today_day].isClosed,
          'q-card-widget-guard-online': channel && channel.online && (channel.orders === 0) && (!product.data.business.hoo[today_day].isClosed) && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed < 90)),
          'q-card-widget-guard-warning': channel && (channel.orders !== 0 && !(product.data.business.hoo[today_day].isClosed || (today_time_elapsed < 0 || today_time_elapsed > 100))) || (channel && channel.online && !product.data.business.hoo[today_day].is24 && (!product.data.business.hoo[today_day].isClosed) && ((today_time_elapsed >= 90 && today_time_elapsed <= 100) || (today_time_elapsed < 0 && today_time_elapsed > -10)))
        }"
        ref="product-card-guardian"
        >
        <template v-if="today_day">
          <q-progress v-if="channel === null" stripe indeterminate color="hint" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="channel === false" stripe :percentage="100" color="hint" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="channel.online === 0 || channel.orders !== 0" :percentage="100" stripe color="protect" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="product.data.business.hoo[today_day].is24" stripe indeterminate color="educate" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="product.data.business.hoo[today_day].isClosed" :percentage="100" stripe color="protect" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="today_time_elapsed >= 0 && today_time_elapsed < 100" stripe indeterminate :color="(today_time_elapsed >= 90) ? 'attention' : 'green-d'" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else-if="(today_time_elapsed < 0 && today_time_elapsed > -10)" stripe indeterminate color="attention" height="8px" style="margin-bottom:-8px"/>
          <q-progress v-else :percentage="100" stripe color="protect" height="8px" style="margin-bottom:-8px"/>
        </template>
        <q-card-title>
          <transition mode="out-in" appear enter-active-class="animated fadeIn animated-d400" leave-active-class="animated fadeOut animated-d200">
          <div :key="channel ? `guardian-info-img-${channel.online}-${channel.orders}` : `guardian-info-img-null`" class="row justify-start items-center">
            <div class="left on-left-lg items-center row text-center justify-center" style="width:55px;height:70px;">
              <img v-if="channel === false" src="/statics/_demo/circle.b3line_white.svg" width="48" class="brighten-50 dark-brighten-100">
              <img v-else-if="!channel" src="/statics/_demo/circles4_white.svg" width="48" class="brighten-50 dark-brighten-100">
              <img v-else-if="channel.orders !== 0 || (channel && channel.online && (!product.data.business.hoo[today_day].isClosed) && (today_time_elapsed >= 90 && today_time_elapsed <= 100))" src="/statics/_demo/exclamationmark_white.svg" width="48">
              <img v-else-if="channel && channel.online && (!product.data.business.hoo[today_day].isClosed) && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed < 90))" src="/statics/_demo/heart_white.svg" width="48">
              <img v-else-if="channel && channel.online && (today_time_elapsed < 0 && today_time_elapsed > -10)" src="/statics/_demo/heart_white.svg" width="48">
              <img v-else-if="channel.online === 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100))" src="/statics/_demo/exclamationmark_white.svg" width="48" class="brighten-50 dark-img-brighten-50 dark-img-invert-100">
              <img v-else src="/statics/_demo/xmark_white.svg" height="48" class="brighten-50 dark-img-brighten-50 dark-img-invert-100">
            </div>
            <div :key="channel ? `guardian-info-text-${channel.online}-${channel.orders}` : `guardian-info-text-null`" class="float-left line-height-sm">
              <div class="font-size-140p capitalize">
                <span v-if="channel === null">{{ $t('CONNECTING') }}</span>
                <span v-else-if="channel === false">{{ $t('NEW') }}</span>
                <span v-else-if="(channel.orders !== 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100))) || (channel.online === 0 && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed <= 100)))">
                  <span v-if="channel.orders === 0">Notice</span>
                  <span v-else>{{ getStatusChangeData(channel.orders).label }}</span>
                </span>
                <span v-else-if="product.data.business.hoo[today_day].is24">Open</span>
                <span v-else-if="product.data.business.hoo[today_day].isClosed">Closed</span>
                <span v-else-if="today_time_elapsed < 0 && today_time_elapsed > -10">Opening Soon</span>
                <span v-else-if="today_time_elapsed < 0 || today_time_elapsed > 100">Closed</span>
                <span v-else-if="today_time_elapsed >= 90 && today_time_elapsed <= 100">Closing Soon</span>
                <span v-else>Open</span>
              </div>
              <div v-if="channel === false" slot="subtitle" class="text-weight-regular font-size-100p block on-top-sm">
                {{ $t('EMPTY_COMMUNICATION') }}
              </div>
              <div v-if="channel" slot="subtitle" class="text-weight-regular font-size-100p block on-top-sm">
                <span>
                  <!-- {{ today_day }} &bull; -->
                  <template v-if="today_day">
                    <template v-if="product.data.business.hoo[today_day].is24">24 Hours</template>
                    <template v-else-if="product.data.business.hoo[today_day].isClosed">Not open today.</template>
                    <template v-else>
                      {{ product.data.business.hoo[today_day].open | t12format }}-{{ product.data.business.hoo[today_day].close | t12format }}
                    </template>
                  </template>
                </span>
                <!-- <span v-else>Check back next time.</span> -->
              </div>
            </div>
          </div>
          </transition>
          <span slot="right" class="absolute text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lg">
            <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
              <div key="chip-work" v-if="/*!isConciergeOnline &&*/ (!clients.length || channelFetchActiveClientsRequestsCount || channelFetchActiveClientsRequestActive)" class="chip-live chip-live-blue">
                <q-icon color="white" name="ion-code-working"/>
              </div>
              <div key="chip-live" v-else-if="isConciergeOnline" class="chip-live">LIVE</div>
              <!-- <div key="chip-offl" v-else class="chip-offline">AWAY</div> -->
            </transition>
            <!-- <transition mode="out-in" appear enter-active-class="animated bounceIn animated-d800" leave-active-class="animated bounceOut animated-d800">
              <span v-if="isConciergeOnline" class="chip-live chip-live-blue">CONCIERGE</span>
              <span v-else class="chip-offline">AWAY</span> -->
              <!-- <span v-if="channel && channel.online && (!product.data.business.hoo[today_day].isClosed) && (product.data.business.hoo[today_day].is24 || (today_time_elapsed >= 0 && today_time_elapsed < 90))" class="chip-live">LIVE</span> -->
              <!-- <span v-else-if="channel && channel.online === false" class="hidden text-subinfo-l">3:22 hours ago</span> -->
            <!-- </transition> -->
          </span>
        </q-card-title>
      </q-card>
    </transition>
    <transition appear enter-active-class="animated fadeInUp animated-d800" leave-active-class="animated fadeOutUp animated-d800">
      <!-- iterate services (if any) -->
      <q-list
        v-if="services && services.length"
        striped gradient-bottom
        class="text-system-brand text-weight-medium q-list-depth"
        style="max-width: 320px !important; margin: 10px auto !important"
      >
        <q-item v-for="(service, serviceIndex) in services" item :key="`service-${serviceIndex}`" :separator="widgetShowSeparator">
          <q-item-side class="text-center">
            <img :src="`/statics/services/${service.iconDetails.categoryId}.${service.iconDetails.componentId}.svg`" width="33"/>
            <img v-if="serviceStatus(service.uuid) >= 2" src="statics/_demo/cross-overlay.svg" class="absolute" style="top: 7px; left: 17px; height: 36px;"/>
          </q-item-side>
          <q-item-main>
            <q-item-tile label class="capitalize text-weight-semibold">
              {{ service.label ? service.label : Wings.services.list[service.iconDetails.categoryId][service.iconDetails.componentId].name }}
            </q-item-tile>
            <q-item-tile v-if="service.label && widgetShowType" sublabel>
              {{ Wings.services.list[service.iconDetails.categoryId][service.iconDetails.componentId].name }}
            </q-item-tile>
            <q-item-tile sublabel v-if="service.components.length === 1 && isCustomDescriptor(product.data.business.components[service.components[0]])">
              <strong :class="serviceStatusClass(serviceStatus(service.uuid))">{{ getComponentDescriptors(product.data.business.components[service.components[0]]).availability[serviceStatus(service.uuid)].label }}</strong>
              <br>{{ getComponentDescriptors(product.data.business.components[service.components[0]]).availability[serviceStatus(service.uuid)].sublabel }}
            </q-item-tile>
            <q-item-tile sublabel v-else>
              <b :class="serviceStatusClass(serviceStatus(service.uuid))">{{ Wings.services.defaults.descriptors.availability[serviceStatus(service.uuid)].label }}</b>
            </q-item-tile>
          </q-item-main>
          <q-item-side right>
            <q-icon :name="serviceStatusIcon(serviceStatus(service.uuid))" :color="serviceStatusClass(serviceStatus(service.uuid)).split('text-')[1]" size="32px"/>
          </q-item-side>
        </q-item>
      </q-list>
    </transition>
  </div>
</template>

<script>
import { axiosLIO } from 'plugins/axios'
import PubNub from 'pubnub'
import { openURL } from 'quasar'

// formatting modules
import moment from 'moment'
import nformat from 'vue-filter-number-format'
import _ from 'lodash'

// Wings
import Wings from '../services/wings-2.js'

export default {
  name: 'LayoutChannelProductWidget',
  filters: {
    nformat: nformat,
    tformat: function (val) {
      return moment(val).format('MMMM Do, YYYY')
    },
    t12format: function (val) {
      return moment(val, 'HH:mm').format('h:mm A')
    },
    dtformat: function (val) {
      return moment(val).format('MM/D/YYYY h:mm A')
    },
    dt12format_EST: function (val) {
      let utcOffset = -(300 + 60 + 60 + 60) / 60
      let currentDateTime = moment(val).utcOffset(utcOffset)
      if (currentDateTime.isDST()) {
        utcOffset += 60
        currentDateTime = moment(val).utcOffset(utcOffset)
      }
      return moment(currentDateTime, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do, YYYY h:mm A')
    }
  },
  data () {
    return {
      uuid: null,
      uri: null,
      pn: null,
      channel: null,
      clients: [],
      channelAdminOnline: null,
      channelOnlineTimer: null,
      channelFetchTimer: null,
      channelFetchActiveClientsRequestsFirstTime: true,
      channelFetchActiveClientsRequestsLastCount: 0,
      channelFetchActiveClientsRequestsCount: 0,
      channelFetchActiveClientsRequestActive: false,
      ready: false,
      datagroups: Wings.datagroups(this),
      services: [],
      Wings: Wings,
      widgetShowType: false,
      widgetShowText: true,
      widgetShowSeparator: false
    }
  },
  meta () {
    return {
      title: ['Wings', this.paramName].join(' · '),
      description: { name: 'description', content: 'Live channel for ' + this.paramName }
    }
  },
  computed: {
    paramName () {
      return this.$route.params.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    channelID () {
      return this.uri ? 'pn_' + this.uri : null
    },
    productFriendlyName () {
      return this.product.data.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    productFullURI () {
      return [document.location.origin, '/channel/', this.product.data.uri].join('')
    },
    productShortURI () {
      return this.product.data.shortlink
    },
    productIcon () {
      if (this.product && this.product.data && this.product.data.business) {
        let website = this.product.data.business.website
        let metas = this.product.data.business.metas
        if (website && metas) {
          let icons = []
          if (metas['twitter:image']) icons.push(metas['twitter:image'])
          if (metas['msapplication-tileimage']) icons.push(metas['msapplication-tileimage'])
          if (icons.length) {
            let icon = icons[0]
            if (icon.indexOf('http') === 0) {
              return icon
            }
            if (website.slice(-1) !== '/') website += '/'
            return website + icon
          }
        }
      }
      return false
    },
    product: {
      get () {
        return this.$store.getters['app/getProducts'].list[0]
      },
      set (product) {
        this.$store.commit('app/updateProductPayload', 0, product)
      }
    },
    isConciergeOnline () {
      return this.clients.length && this.clients.some(client => client.state && client.state.role === 'admin')
    },
    amIOnline () {
      return this.clients.length && this.clients.some(client => client.uuid === this.uuid)
    },
    today_day: {
      get () {
        // assume utc_offset in minutes
        let utcOffset = this.product.data.business.timezone.utc_offset
        const date = new Date(Date.now() + utcOffset * 60 * 1000)
        const dayOfWeek = date.getUTCDay()

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        return daysOfWeek[dayOfWeek]
      }
    },
    today_current_time: {
      get () {
        let utcOffset = this.product.data.business.timezone.utc_offset
        // hardcode DST
        if (utcOffset === -300) {
          utcOffset += 60
        }
        let currentTime = moment().utcOffset(utcOffset / 60) // assume utc_offset in minutes
        // if (currentTime.isDST()) {
        //   utcOffset += 60
        //   currentTime = moment().utcOffset(utcOffset)
        // }
        return moment(currentTime, 'HH:mm:ss').add(this.clock, 'seconds').format('h:mm A')
      }
    },
    today_time_elapsed: {
      get () {
        let utcOffset = this.product.data.business.timezone.utc_offset
        // hardcode DST
        if (utcOffset === -300) {
          utcOffset += 60
        }
        let currentTime = moment().utcOffset(utcOffset / 60) // assume utc_offset in minutes
        // if (currentTime.isDST()) {
        //   utcOffset += 60
        //   currentTime = moment().utcOffset(utcOffset)
        // }
        currentTime = moment(this.today_current_time, 'h:mm A') // .utcOffset(utcOffset, true)
        const openingTime = moment(this.product.data.business.hoo[this.today_day].open, 'HH:mm').utcOffset(utcOffset)
        const closingTime = moment(this.product.data.business.hoo[this.today_day].close, 'HH:mm').utcOffset(utcOffset)
        const businessDayDuration = closingTime.diff(openingTime, 'seconds')
        const timeElapsed = currentTime.diff(openingTime, 'seconds')
        // const timeRemaining = closingTime.diff(currentTime, 'seconds')
        const percentageElapsed = (timeElapsed / businessDayDuration) * 100
        // const percentageRemaining = (timeRemaining / businessDayDuration) * 100
        return percentageElapsed.toFixed(2)
      }
    }
  },
  beforeMount () {
    // query product information
    axiosLIO.get('/channel/' + this.uri).then((res) => {
      console.log(res)
      let product = res.data.data.product
      product.data = JSON.parse(product.payload)
      this.$store.commit('app/updateProducts', { list: [product], group: {} })

      // get services list (if any)
      let services = this.$route.query.services && this.$route.query.services.split(',')
      // process widget options (if any)
      if (this.$route.query.type) {
        this.widgetShowType = this.$route.query.type
      }
      if (this.$route.query.optext) {
        this.widgetShowText = this.$route.query.optext === '1'
      }
      if (this.$route.query.sep) {
        this.widgetShowSeparator = this.$route.query.sep === '1'
      }
      // process
      for (let serviceIndex in services) {
        let serviceUUID = services[serviceIndex]
        let serviceData = this.computeServiceProperties(serviceUUID)
        if (serviceData) this.services.push(serviceData)
      }

      this.ready = true
    })
  },
  created () {
    console.log(':: refs', this.$refs)
    // this.$refs.bar.stop()
    // uuid
    this.uuid = localStorage.getItem('uuid')
    if (!this.uuid) {
      this.uuid = this.$guid.noHyphen(this.$guid.generate())
    }
    localStorage.setItem('uuid', this.uuid)
    // init Wings data
    Wings.DFID = {}
    Wings.DGID = {}
    for (let dg in this.datagroups) {
      Wings.DGID[this.datagroups[dg].id.toUpperCase()] = 1 * dg
      for (let df in this.datagroups[dg].datafields) {
        Wings.DFID[this.datagroups[dg].datafields[df].id] = { datagroup: dg, datafield: df }
        Wings.DGID[[this.datagroups[dg].id, this.datagroups[dg].datafields[df].id].join('_').toUpperCase()] = 1 * df
      }
    }
    // connect to PN
    this.channelInit()
  },
  methods: {
    openURL,
    datafieldsInit () {
      this.datagroups.forEach((g, i) => {
        g.datafields.forEach((f, j) => {
          // initialize values
          if (f.valueType) {
            if (f.valueType.name === 'Boolean') {
              this.datagroups[i].datafields[j].value = {
                option: 0,
                options: [{
                  bTrue: true,
                  label: this.$t('YES')
                }, {
                  bTrue: false,
                  label: this.$t('NO')
                }]
              }
            }
          }
          // initialize option
          if (typeof this.product.data.channel[f.id] === 'undefined') {
            this.product.data.channel[f.id] = this.datagroups[i].datafields[j].value.option || 0
          } else {
            this.datagroups[i].datafields[j].value.option = this.product.data.channel[f.id]
          }
          // create signals
          this.datagroups[i].datafields[j].signal = {
            // create update method
            update: (option) => {
              this.product.data.channel[f.id] = option
              // this.channelPublish()
              // this.mutateProduct(this.product.id, this.product.data, `SIGNAL.${f.id}: ${this.product.data.channel[f.id]}`, () => {})
            },
            updateAll: (ch) => {
              this.product.data.channel = ch
            },
            // create descriptor
            bCheck: () => {
              let bTrue = this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].bTrue
              return (bTrue === true || bTrue === false) ? bTrue : null
            },
            descriptor: () => {
              return this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].label
            }
          }
        })
      })
    },
    datagroupData (g, f) {
      let datagroups = Wings.datagroups(this)
      let groupIndex = Wings.DGID[g.toUpperCase()]
      let fieldIndex = Wings.DGID[[g.toUpperCase(), f.toUpperCase()].join('_')]
      let path = datagroups[groupIndex].datafields[fieldIndex]
      return path
    },
    datagroupDataValue (g, f, option, sh = false) {
      let labelOption = ['label', sh ? '_sh' : ''].join('')
      return this.datagroupData(g, f).value.options[option][labelOption]
    },
    channelInit (done) {
      this.pn = null
      if (done) done()
      this.channelConnect()
      this.channelSubscribe()
      this.channelSetState()
      this.channelSeekHistory()
      this.channelFetchUpdater()
      this.channelOnlineVerifier()
    },
    channelFetchUpdater () {
      // only fetch after 2 seconds of sequential multi-requests
      this.channelFetchTimer = setInterval(() => {
        // console.log(`:: Channel: Presense: Checking: Last=${this.channelFetchActiveClientsRequestsLastCount}, Now=${this.channelFetchActiveClientsRequestsCount}`)
        // are the requests count different
        if (this.channelFetchActiveClientsRequestsLastCount < this.channelFetchActiveClientsRequestsCount) {
          this.channelFetchActiveClientsRequestsLastCount = this.channelFetchActiveClientsRequestsCount
          return
        }
        if (this.channelFetchActiveClientsRequestsCount > 0 && this.channelFetchActiveClientsRequestsCount === this.channelFetchActiveClientsRequestsLastCount) {
          this.channelFetchActiveClientsRequestsLastCount = 0
          this.channelFetchActiveClientsRequestsCount = 0
          this.channelFetchActiveClientsRequest()
        }
      }, 2000)
    },
    channelOnlineVerifier () {
      // we need to force a reload
      // if the _current_ user is not in the list of clients
      // check every 1 minute now
      this.channelOnlineTimer = setInterval(() => {
        // console.log(`:: Channel: Presense: AmIOnline?`)
        if (!this.amIOnline) {
          // console.log(`:: Channel: Presense: AmIOnline: NO`)
          // console.log(`:: Channel: Presense: Restart`)
          // refresh the connection
          this.channelDisconnect()
          this.channelInit()
        }
      }, 60 * 1000)
    },
    channelFetchActiveClients () {
      if (this.channelFetchActiveClientsRequestsFirstTime) {
        this.channelFetchActiveClientsRequestsFirstTime = false
        // console.log(`:: Channel: Presense: Requst Trigger: FirstTime (0-delay)`)
        this.channelFetchActiveClientsRequest()
        return
      }
      this.channelFetchActiveClientsRequestsCount++
      // console.log(`:: Channel: Presense: Request Trigger: @${this.channelFetchActiveClientsRequestsCount}`)
    },
    channelFetchActiveClientsRequest () {
      this.channelFetchActiveClientsRequestActive = true
      const randId = this.$guid.generate()
      // console.log(`:: Channel: Presense: HERENOW: Requested #${randId}`)
      this.pn.hereNow({
        channels: [this.channelID],
        includeUUIDs: true,
        includeState: true
      }, (status, response) => {
        // console.log(`:: Channel: Presense: HERENOW: Responded #${randId}`)
        // console.log(':: Channel: Presense: HERENOW: d: ', response)
        // console.log(':: Channel: Presense: --------------------------------------')
        this.clients = response.channels[this.channelID].occupants
        this.channelFetchActiveClientsRequestActive = false
      })
    },
    encodeUUID (uuid) {
      // Remove hyphens and convert to binary
      const hex = uuid.replace(/-/g, '')
      const bin = Buffer.from(hex, 'hex')
      // Convert binary to Base64
      return bin.toString('base64').replace(/=/g, '')
    },
    decodeUUID (uuidEncoded) {
      // Decode from Base64
      const bin = Buffer.from(uuidEncoded, 'base64')
      // Convert binary to hex
      let hex = bin.toString('hex')
      // Re-insert hyphens to get the UUID in standard format
      return hex.substring(0, 8) + '-' + hex.substring(8, 12) + '-' + hex.substring(12, 16) + '-' + hex.substring(16, 20) + '-' + hex.substring(20)
    },
    channelConnect () {
      // update/check URI
      this.uri = this.$route.params.uri
      this.pn = new PubNub({
        subscribeKey: 'sub-c-6ef8f7b4-860c-11e9-99de-d6d3b84c4a25',
        uuid: this.uuid,
        ssl: true,
        autoNetworkDetection: true
      })
      this.pn.addListener({
        message: (m) => {
          this.channel = m.message

          if (m.message.component && m.message.component.uuid) {
            // we have component status updates
            try {
              this.product.data.business.components[this.decodeUUID(m.message.component.uuid)].status = m.message.component.status
            } catch (e) {}
          }
        }
      })
    },
    channelSubscribe () {
      this.pn.subscribe({
        channels: [this.channelID, `${this.channelID}.requests`],
        withPresence: true
      })
      // listen for the admin
      this.pn.addListener({
        presence: (e) => {
          this.channelFetchActiveClients()
        },
        status: (statusEvent) => {
          if (statusEvent.category === 'PNConnectedCategory') {
            this.channelFetchActiveClients()
          }
        }
      })
    },
    channelSeekHistory (done) {
      this.channel = null
      if (done) done()
      if (this.pn) {
        return this.pn.history({ channel: this.channelID, count: 1 }, (status, response) => {
          if (!status.error) {
            setTimeout(() => {
              this.channel = response.messages.length ? response.messages[0].entry : false
            }, 1400)
          }
        })
      }
      return false
    },
    channelSetState () {
      this.pn.setState({
        state: {
          observing: true,
          isWidget: true
        },
        uuid: this.uuid,
        channels: [this.channelID]
      }, function (r) {
        console.log(':: channelSetState')
        console.log(':: :: fn()')
        console.log(r)
      })
    },
    computeServiceProperties (serviceUUID) {
      let service = _.cloneDeep(this.product.data.business.services[serviceUUID])
      if (!service) return null
      // add service data
      let serviceComputed = service
      // add private details
      serviceComputed.private = serviceComputed.private || false
      // add space details
      serviceComputed.spaceDetails = {
        spaceIndex: null // All
      }
      // add set details
      serviceComputed.setDetails = {
        isSet: serviceComputed.components && serviceComputed.components.length > 1,
        totalComponents: serviceComputed.components.length
      }
      // add icon details
      serviceComputed.iconDetails = this.product.data.business.components[service.icon]
      // add categories details
      serviceComputed.categoriesDetails = {}
      // add status details
      serviceComputed.statusDetails = {
        components: [],
        status: null,
        updated: 0
      }
      // add dependencies details
      let dependencies = serviceComputed.dependencies || []
      serviceComputed.dependencies = dependencies
      serviceComputed.dependenciesDetails = {
        dependencies: dependencies
      }
      // add dependents details
      // serviceComputed.dependentsDetails = this.computeDependents(serviceUUID)
      // add components details
      serviceComputed.componentsDetails = {}
      for (let componentIndex in serviceComputed.components) {
        let componentUUID = serviceComputed.components[componentIndex]
        let componentData = this.product.data.business.components[componentUUID]
        serviceComputed.componentsDetails[componentUUID] = componentData
        // update category details
        if (!serviceComputed.categoriesDetails[componentData.categoryId]) {
          serviceComputed.categoriesDetails[componentData.categoryId] = []
        }
        serviceComputed.categoriesDetails[componentData.categoryId].push(componentData.componentId)
        // update status details
        serviceComputed.statusDetails.components.push(componentData.status)
        if (serviceComputed.statusDetails.updated < componentData.updated) {
          serviceComputed.statusDetails.updated = componentData.updated
        }
      }
      serviceComputed.statusDetails.status = this.serviceStatus(serviceUUID)
      return serviceComputed
    },
    isCustomDescriptor (component) {
      return Wings.services.list[component.categoryId][component.componentId].descriptors || false
    },
    getComponentDescriptors (component) {
      return Wings.services.list[component.categoryId][component.componentId].descriptors || Wings.services.defaults.descriptors
    },
    serviceStatusClass (serviceStatus) {
      return ['text-educate', 'text-attention', 'text-protect', 'text-purple-l2 statusFinder', 'text-shallow'][serviceStatus]
    },
    serviceStatusIcon (serviceStatus) {
      return [
        'ion-checkmark-circle',
        'ion-alert',
        'ion-close-circle',
        'ion-code-working',
        'ion-code'
      ][serviceStatus]
    },
    serviceStatus (serviceUUID) {
      let status = null, componentsStatus = []
      let service = this.product.data.business.services[serviceUUID]
      // compute components
      for (let componentIndex in service.components) {
        let componentUUID = service.components[componentIndex]
        componentsStatus.push(this.product.data.business.components[componentUUID].status)
      }
      // compute dependencies
      for (let dependencyIndex in service.dependencies) {
        let serviceUUID = service.dependencies[dependencyIndex]
        componentsStatus.push(this.serviceStatus(serviceUUID))
      }
      status = componentsStatus.some(c => c === null) ? null : Math.max(...componentsStatus)
      return status
    },
    getStatusChangeData (status) {
      return {
        1: {
          label: 'Be right back',
          icon: '/statics/_demo/time-closed_brb.svg'
        },
        2: {
          label: 'Closed for the day',
          icon: '/statics/_demo/time-closed.svg'
        },
        3: {
          label: 'Closing early',
          icon: '/statics/_demo/time-closing_early.svg'
        },
        4: {
          label: 'Limited hours',
          icon: '/statics/_demo/time-limited.svg'
        },
        5: {
          label: 'Delayed opening',
          icon: '/statics/_demo/time-delayed_opening.svg'
        },
        6: {
          label: 'Lunch break',
          icon: '/statics/_demo/time-lunch_break.svg'
        }
      }[status]
    }
  }
}
</script>

<style lang="stylus">
.q-loading-bar.top.bg-primary
  display none

.q-progress, .q-progress-track, .q-progress-model
  border-radius 0 !important

.q-card-widget-guard
  background #fff
  box-shadow 0 0 41px 34px rgba(0,0,0,0.06) !important
  .q-card-title
    color #555555
    font-weight 700
.q-card-widget-guard-online
  background-image linear-gradient(180deg, #21BA45 0%, #15862f 100%) !important
  .q-card-title
    color white
    font-weight 700
.q-card-widget-guard-offline
  background-image linear-gradient(180deg, #555555 0%, #3D3D3D 100%) !important
  .q-card-title
    color white
    font-weight 700
.q-card-widget-guard-warning
  background-image linear-gradient(180deg, #F57313 0%, #CD5F0D 100%) !important
  .q-card-title
    color white
    font-weight 700

html, body
  background-color transparent !important

.q-card
  max-width 80%
@media only screen and (min-width: 328px)
  .q-card
    max-width 280px
@media only screen and (min-width: 320px)
  .q-card
    max-width 90%
@media only screen and (min-width: 400px)
  .q-card
    max-width 390px

</style>
